import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Content, { HTMLContent } from "../components/Content"
import "./about.css"

export const AboutPageTemplate = ({
  header,
  paragraph,
  image,
  preview,
  contentComponent,
}) => {
  const PageComponent = contentComponent || Content
  return (
    <div className="about-page-container">
      <h2 className="page-header">{header}</h2>
      {preview ? (
        <img src={image} alt="preview" className="about-img preview-img" />
      ) : (
        <Img fluid={image} className="about-img" />
      )}
      <PageComponent content={paragraph} className="about-content" />
    </div>
  )
}

const AboutPage = ({ data, location }) => {
  const { html, frontmatter } = data.markdownRemark
  const { header, image } = frontmatter

  return (
    <Layout location={location} seoTitle="O mnie">
      <AboutPageTemplate
        header={header}
        paragraph={html}
        image={image.childImageSharp.fluid}
        contentComponent={HTMLContent}
        preview={false}
      />
    </Layout>
  )
}

export default AboutPage

export const AboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header
        image {
          childImageSharp {
            fluid(maxWidth: 720, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
